import React from "react"
import qs from "qs"

import { notifyUser as apiNotifyUser } from "../services/tenantsApi"

import PageWrapper from "../components/PageWrapper"
import Form from "../components/Form"

import * as landingPageStyles from "./win1000.module.css"

import Logo from "../assets/Bunch_Inline.png"

const notifyMeOptions = {
  initialPayload: {
    name: "",
    email: "",
    phone: "",
    university: "",
    numberOfBedrooms: "",
    consentToBeContacted: true,
  },
  fields: [
    {
      placeholder: "First name",
      type: "text",
      required: true,
      name: "firstName",
    },
    {
      placeholder: "Last name",
      type: "text",
      required: true,
      name: "lastName",
    },
    {
      placeholder: "Email address",
      type: "email",
      required: true,
      name: "email",
    },
    {
      placeholder: "Phone number",
      type: "tel",
      required: true,
      name: "phone",
    },
    {
      label: "University",
      type: "dropdown",
      required: true,
      name: "university",
      options: [
        {
          label: "Bristol",
          value: "Bristol",
        },
        {
          label: "Manchester",
          value: "Manchester",
        },
        {
          label: "Leeds",
          value: "Leeds",
        },
      ],
    },
    {
      label: "House size",
      type: "range",
      required: false,
      name: "numberOfBedrooms",
      min: 1,
      max: 12,
      defaultValue: 1,
      marks: [
        {
          value: 1,
          label: "1",
        },
        {
          value: 12,
          label: "12",
        },
      ],
    },
    {
      label: "Do you have an Ambassador code?",
      type: "switch",
      name: "haveAnAmbassadorCode",
    },
    {
      showIfTrue: "haveAnAmbassadorCode",
      placeholder: "Ambassador code",
      type: "text",
      name: "ambassadorCode",
    },
    {
      label: "I consent to be contacted by Bunch",
      type: "checkbox",
      name: "consentToBeContacted",
    },
  ],
  submitText: "Submit",
  submitButtonStyle: {
    margin: "0 auto",
  },
}

export default ({ location, pageContext }) => {
  const initalQueryStrings = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  })

  const [notifyMeError, setNotifyMeError] = React.useState(null)
  const [notifyMeMessage, setNotifyMeMessage] = React.useState(null)
  const [settingUpNotifyMe, setSettingUpNotifyMe] = React.useState(null)

  const notifyMe = async (payload, onSuccess) => {
    console.log(payload)
    try {
      const queryStrings = qs.parse(location.search, {
        ignoreQueryPrefix: true,
      })

      setSettingUpNotifyMe(true)
      setNotifyMeError("")
      setNotifyMeMessage("")
      await apiNotifyUser({
        ...payload,
        city: queryStrings.address,
        numberOfBedrooms: payload.numberOfBedrooms.toString(),
        source: "win1000",
        referralCode: initalQueryStrings.fpr,
      })
      setNotifyMeMessage("Entry submitted! Go check out our properties.")
      onSuccess()
      setSettingUpNotifyMe(false)
    } catch (e) {
      console.log(e)
      setNotifyMeError("An error occurred, please try again.")
      setSettingUpNotifyMe(false)
    }
  }

  const renderContent = () => {
    return (
      <div className={landingPageStyles.win1000}>
        <div style={{ textAlign: "left", marginBottom: "20px" }}>
          <a href="https://www.the-bunch.co.uk">
            <img alt="The Bunch logo" style={{ height: "60px" }} src={Logo} />
          </a>
        </div>
        <div className="sectionHeader" style={{ color: "#fff", fontSize: 40 }}>
          Win £1000
        </div>
        <div className="sectionSubtitle" style={{ color: "#fff" }}>
          Our student properties in Bristol, Leeds & Manchester are now live!
        </div>
        <div className="sectionDescription" style={{ color: "#fff" }}>
          Simply fill out the form below to be entered into our launch
          competition.
        </div>
        <img
          alt="Win £1000"
          className={landingPageStyles.win1000ScreenshotMobile}
          src={require("../assets/win-1000.jpg")}
        />
        <div className={landingPageStyles.content}>
          <Form
            options={notifyMeOptions}
            initialPayload={notifyMeOptions.initialPayload}
            onSubmit={(payload, onSuccess) => notifyMe(payload, onSuccess)}
            submitting={settingUpNotifyMe}
            apiErrorMessage={notifyMeError}
            apiSuccessMessage={notifyMeMessage}
            hideButtonOnSubmit={true}
          />
          <img
            alt="Win £1000"
            className={landingPageStyles.win1000Screenshot}
            src={require("../assets/win-1000.jpg")}
          />
        </div>
      </div>
    )
  }

  return (
    <PageWrapper
      location={location}
      backgroundColor="#21544f"
      hideHeaderBorder={true}
      hideHeader={true}
    >
      <div id="beattherush" className={landingPageStyles.win1000}>
        {renderContent()}
      </div>
    </PageWrapper>
  )
}
